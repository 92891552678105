import React from "react";

function InviteHero({ className }) {
  function openWindow(url) {
    const width = 390;
    const height = 630;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const features = `width=${width},height=${height},top=${top},left=${left}`;
    window.open(url, "_blank", features);
  }

  return (
    <div
      id="invite"
      className={`flex max-md:flex-col shadow-md flex-row justify-between relative gap-4 bg-gradient-to-b from-dark to-bg-primary p-8 items-center m-auto rounded-lg text-text-primary ${className}`}
    >
      <div className="flex flex-col">
        <img
          src={`${process.env.PUBLIC_URL}/wave.png`}
          alt="wave"
          className="left-0 absolute top-0 w-full h-full"
        />
        <div className="flex flex-col items-start justify-between gap-4 mt-4 z-50">
          <h1 className="text-3xl max-md:text-2xl text-center font-medium lg:text-right z-50 ltr">
            اضف البوت لسيرفرك واستمتع بالمزايا الرائعة
          </h1>
          <button
            onClick={(e) =>
              openWindow(
                "https://discord.com/api/oauth2/authorize?client_id=728782652454469662&permissions=8&scope=bot%20applications.commands"
              )
            }
            className="bg-dark text-text-primary max-md:m-auto p-2 rounded-md w-24 hover:scale-110 transition-all"
          >
            اضافة البوت
          </button>
        </div>
      </div>
      <div className="relative z-50">
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="logo"
          className="h-32 m-2 lg:p-0"
        />
      </div>
    </div>
  );
}

export default InviteHero;
