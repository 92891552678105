import React, { useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faPause,
  faPlay,
  faX,
  faAngleLeft,
  faAngleRight,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router";

function Azkar() {
  const navigate = useNavigate();
  const { tabId } = useParams();

  const [tab, setTab] = useState(tabId || "");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [audioLoading, setAudioLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sidebarPage, setSidebarPage] = useState(1);
  const [azkarPerPage] = useState(25);
  const [counts, setCounts] = useState({});

  const audioElements = useRef({});

  window.addEventListener("resize", () => {
    if (window.innerWidth > 1024) {
      setSidebarOpen(false);
    }
  });

  const handleSidePageChange = (pageNumber) => {
    setSidebarPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setSidebarOpen(false);
  }, [tab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/json/azkar`
        );
        const data = await response.json();
        setData(data);
        setLoading(false);

        const uniqueCategories = [
          ...new Set([
            ...(data.azkar || []).map((item) => item.category),
            ...(data.dua || []).map((item) => item.category),
          ]),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlePlayPause = (audioUrl) => {
    if (playingAudio === audioUrl) {
      const audio = audioElements.current[audioUrl];
      if (audio.paused) {
        const promise = audio.play();
        if (promise !== undefined) {
          setAudioLoading(true);
          promise
            .then(() => {
              setAudioLoading(false);
            })
            .catch((error) => {
              console.error("Error playing audio:", error);
              setAudioLoading(false);
            });
        }
      } else {
        audio.pause();
        setPlayingAudio(null);
      }
    } else {
      if (playingAudio) {
        audioElements.current[playingAudio].pause();
      }
      setPlayingAudio(audioUrl);
      if (!audioElements.current[audioUrl]) {
        audioElements.current[audioUrl] = new Audio(audioUrl);
        audioElements.current[audioUrl].addEventListener("ended", () => {
          setPlayingAudio(null);
        });
      }
      audioElements.current[audioUrl].play();
      if (audioElements.current[audioUrl].readyState < 3) {
        setAudioLoading(true);
        audioElements.current[audioUrl].onloadedmetadata = () => {
          setAudioLoading(false);
        };
      }
    }
  };
  const filteredCategories = categories.filter((category) =>
    category.toLowerCase().includes(search.toLowerCase())
  );
  const [categoriesPerPage] = useState(30);

  const indexOfLastCategory = sidebarPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = filteredCategories.slice(
    indexOfFirstCategory,
    indexOfLastCategory
  );

  const totalPages = Math.ceil(filteredCategories.length / categoriesPerPage);

  const indexOfLastAzkar = currentPage * azkarPerPage;
  const indexOfFirstAzkar = indexOfLastAzkar - azkarPerPage;
  const currentAzkar = [...(data.azkar || []), ...(data.dua || [])]
    .filter((item) => (tab ? item.category === tab : item))
    .slice(indexOfFirstAzkar, indexOfLastAzkar);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const isLastPage =
    currentPage ===
    Math.ceil(
      ((data.azkar?.length || 0) + (data.dua?.length || 0)) / azkarPerPage
    );

  const handleTabChange = (category) => {
    setTab(category.split("_").join(" "));
    setCurrentPage(1);

    navigate(`/azkar/${category.split(" ").join("_")}`);
  };

  const tabFilter =
    tabId !== undefined && categories.length > 0 ? true : false;

  useEffect(() => {
    if(tabFilter === true) {
      if (categories.includes(tabId.split("_").join(" "))) {
        handleTabChange(tabId);
    }
    }
    // eslint-disable-next-line
  }, [tabFilter]);

  const count = (id, reset, event) => {
    if (reset) {
      setCounts((prevCounts) => ({
        ...prevCounts,
        [id]: 0,
      }));
      event.stopPropagation();
    } else {
      setCounts((prevCounts) => ({
        ...prevCounts,
        [id]: (prevCounts[id] || 0) + 1,
      }));
    }
  };

  return (
    <div className="min-h-screen bg-image flex flex-row py-36 max-lg:py-24 relative flex-wrap lg:flex-nowrap">
      <button
        className={`text-white p-2 rounded-md w-24 transition-all lg:hidden relative z-50 active:scale-110 hover:scale-110`}
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <FontAwesomeIcon icon={sidebarOpen ? faX : faBars} />
      </button>
      <div
        className={`w-full lg:w-1/3 relative z-50 lg:block ${
          sidebarOpen ? `block` : `hidden`
        }`}
      >
        <div className="flex flex-col justify-start p-8 h-full">
          <h1 className="text-2xl text-white font-medium text-right">الفهرس</h1>
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="ابحث في الفهرس"
            className="focus:outline-none w-fit p-2 pl-8 rounded-md bg-bg-primary text-white placeholder:text-gray-400 h-10 my-4"
          />
          <button
            key={tab}
            onClick={() => handleTabChange("")}
            className={`text-right text-white w-fit p-2 mt-2 border-r-2 bg-transparent ${
              tab === ""
                ? "bg-gradient-to-r from-bg-primary to-transparent border-bg-secondary"
                : "border-bg-primary"
            }`}
          >
            الكل
          </button>
          {currentCategories.map((category, index) => (
            <button
              key={category}
              onClick={() => handleTabChange(category)}
              className={`text-right text-white w-fit p-2 mt-2 border-r-2 bg-transparent ${
                tab === category
                  ? "bg-gradient-to-r from-bg-primary to-transparent border-bg-secondary"
                  : "border-bg-primary"
              }`}
            >
              {category}
            </button>
          ))}
          {filteredCategories.length > categoriesPerPage && (
            <div className="flex flex-row-reverse justify-between w-fit py-4 gap-6">
              <button
                onClick={() => {
                  handleSidePageChange(sidebarPage - 1);
                }}
                className={`md:px-3 hover:scale-110 w-fit text-sm transition-all py-1 mx-1 text-white rounded-full flex flex-row gap-2 items-center disabled:opacity-50`}
                disabled={sidebarPage <= 1}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
              <span className="text-white">
                <span className="font-number">{sidebarPage}</span> من{" "}
                <span className="font-number">{totalPages}</span>
              </span>
              <button
                onClick={() => {
                  handleSidePageChange(sidebarPage + 1);
                }}
                className={`md:px-3 hover:scale-110 text-sm w-fit transition-all py-1 mx-1 text-white rounded-full flex flex-row gap-2 items-center disabled:opacity-50`}
                disabled={sidebarPage === totalPages}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="w-full lg:w-3/4 p-8 overflow-y-auto">
        {loading ? (
          <div className="text-center h-screen m-auto flex relative z-50">
            <div role="status" className="m-auto">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div className={`${sidebarOpen ? `hidden` : `block`}`}>
            <h1 className="text-4xl text-primary py-4 text-center">
              {tab.split("_").join(" ") || "الأذكار والأدعية"}
            </h1>
            <div className="mt-4 relative z-50 my-4">
              {[...(data.azkar || []), ...(data.dua || [])].filter((item) =>
                tab ? item.category === tab : item
              ).length > azkarPerPage && (
                <div className="flex flex-row-reverse justify-center gap-6">
                  <button
                    onClick={() => {
                      paginate(currentPage - 1);
                    }}
                    className={`md:px-3 hover:scale-110 transition-all py-1 mx-1 text-white rounded-full flex flex-row gap-2 items-center disabled:opacity-50`}
                    disabled={currentPage <= 1}
                  >
                    الصفحة السابقة
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <span className="text-white">
                    <span className="font-number">{currentPage}</span> من{" "}
                    <span className="font-number">
                      {currentAzkar.length < azkarPerPage && currentPage === 1
                        ? 1
                        : Math.ceil(
                            (tab
                              ? [
                                  ...(data.azkar || []),
                                  ...(data.dua || []),
                                ].filter((item) =>
                                  tab ? item.category === tab : item
                                ).length
                              : (data.azkar?.length || 0) +
                                (data.dua?.length || 0)) / azkarPerPage
                          )}
                    </span>
                  </span>
                  <button
                    onClick={() => {
                      paginate(currentPage + 1);
                    }}
                    className={`md:px-3 hover:scale-110 transition-all py-1 mx-1 text-white rounded-full flex flex-row gap-2 items-center disabled:opacity-50`}
                    disabled={isLastPage || currentAzkar.length < azkarPerPage}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                    الصفحة التالية
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4 w-full">
              {currentAzkar.map((item) => (
                <div key={item.id} className="flex-col gap-0">
                  <div
                    className={`flex flex-col text-right gap-2 p-2 rounded-md justify-center shadow-md bg-bg-primary/60 w-full ml-auto relative`}
                  >
                    <div className="flex gap-4 items-start">
                      <div className="flex flex-col-reverse gap-2 items-center">
                        <div className="relative z-50">
                          <button
                            disabled={
                              item.number > 0 && counts[item.Id] >= item.number
                            }
                            onClick={(e) => {
                              count(item.Id, false, e);
                            }}
                            className="text-center relative text-white text-2xl bg-bg-primary border-2 border-bg-secondary rounded-full p-6 w-10 h-10 flex justify-center items-center font-digital disabled:opacity-50 transition-all"
                          >
                            {counts[item.Id] || 0}
                          </button>
                          <button
                            onClick={(e) => {
                              count(item.Id, true, e);
                            }}
                            className={`transition-all w-6 h-6 rounded-full absolute top-8 text-white right-9 text-lg bg-bg-secondary ${
                              counts[item.Id] > 0
                                ? `opacity-100`
                                : `opacity-0 z-[-1]`
                            }`}
                          >
                            <FontAwesomeIcon icon={faRotateRight} size="sm" />
                          </button>
                        </div>
                        {item.audio && (
                          <button
                            onClick={() => handlePlayPause(item.audio)}
                            className={`text-white px-4 w-8 h-8 flex justify-center items-center`}
                            disabled={
                              audioLoading && playingAudio === item.audio
                            }
                          >
                            {playingAudio === item.audio && audioLoading ? (
                              <div className="text-center relative z-50">
                                <div role="status">
                                  <svg
                                    aria-hidden="true"
                                    className="inline w-10 h-10 p-3 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </div>
                              </div>
                            ) : (
                              <FontAwesomeIcon
                                icon={
                                  playingAudio === item.audio &&
                                  !audioElements.current[item.audio].paused
                                    ? faPause
                                    : faPlay
                                }
                              />
                            )}
                          </button>
                        )}
                      </div>
                      <h3 className="text-lg text-white flex gap-2 items-center w-full">
                        {item.zekr}
                      </h3>
                    </div>
                    <p className="text-gray-400">
                      {item.description.arabic || ""}
                    </p>
                    {!tab && (
                      <p className="text-sm text-gray-400">{item.category}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Azkar;
