import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopContext = createContext();

export const useScrollToTop = () => useContext(ScrollToTopContext);

export const ScrollToTopProvider = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

    
  return (
    <ScrollToTopContext.Provider value={{}}>
      {children}
    </ScrollToTopContext.Provider>
  );
};