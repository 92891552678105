import React from "react";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";

function Hero() {
  const [servers, setServers] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchServers = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/large/servers`
    );
    const data = await response.json();
    setServers(data.top);
    setCount(data.count);
    setLoading(false);
  };

  useEffect(() => {
    fetchServers();
  }, []);

  function openWindow(url) {
    const width = 390;
    const height = 630;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const features = `width=${width},height=${height},top=${top},left=${left}`;
    window.open(url, "_blank", features);
  }

  return (
    <div id="start" className="relative">
      <div className="flex flex-col p-4 items-center justify-center bg-image min-h-screen text-text-primary w-full m-auto bg-image">
        <h1 className="text-6xl lg:text-7xl font-medium z-10 py-2">
          بوت فإذكروني
        </h1>
        <p className="text-lg mt-4 z-10">
          أول بوت إسلامي متكامل في الشرق الأوسط في ديسكورد ويتضمن جميع الميزات
          التي تساعد المسلمين على العبادة ، وإحياء سنة ذكر الله
        </p>
        <div className="flex gap-4 mt-4 z-10">
          <button
            onClick={(e) =>
              openWindow(
                "https://discord.com/api/oauth2/authorize?client_id=728782652454469662&permissions=8&scope=bot%20applications.commands"
              )
            }
            className="bg-bg-primary relative z-10 text-text-primary p-2 rounded-md w-24 hover:scale-110 transition-all"
          >
            اضافة البوت
          </button>
          <a
            className="bg-text-primary relative z-10 text-dark p-2 rounded-md w-24 hover:scale-110 transition-all"
            href="#features"
          >
            المميزات
          </a>
        </div>
      </div>
      <div className="relative w-full z-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 250"
          className="fill-dark w-full z-0 absolute bottom-0"
        >
          <path
            fillOpacity="1"
            d="M0,32L80,64C160,96,250,160,480,160C640,160,800,96,960,74.7C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <>
        {loading && (
          <div className="text-center w-full bg-dark relative z-50">
            <div role="status" className="m-auto absolute right-0 left-0">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        )}
        <>
          <div className={`min-h-32 bg-dark z-0 pb-24 relative`}>
            <h1
              className={`text-4xl text-center z-0 bg-dark text-white px2 py-8 max-md:text-xl ${
                loading ? `opacity-0` : `opacity-100`
              } transition-all`}
            >
              يستخدم البوت في أكثر من{" "}
              <span className="font-number">
                {count.toLocaleString().split(",")[0]}
              </span>{" "}
              الف سيرفر{" "}
            </h1>
            <Marquee autoFill pauseOnClick style={{ direction: "initial" }}>
              {servers.map((server, index) => (
                <div
                  key={index}
                  className="card flex gap-2 items-center p-5 bg-bg-primary rounded-md text-left m-4 min-w-[300px] max-w-[300px]"
                >
                  <img
                    src={server.iconURL}
                    alt="server icon"
                    className="h-16 w-16 rounded-md"
                  />
                  <div className="w-full">
                    <p
                      className={`text-white max-md:text-lg flex items-center m-auto gap-1 ${
                        server.name.length > 12 ? `text-sm` : `text-xl`
                      }`}
                    >
                      {server.features.includes("VERIFIED") && (
                        <svg
                          aria-hidden="false"
                          width="20"
                          height="20"
                          viewBox="0 0 16 15.2"
                        >
                          <path
                            fill="#26a65b"
                            className="p-1"
                            fill-rule="evenodd"
                            d="m16 7.6c0 .79-1.28 1.38-1.52 2.09s.44 2 0 2.59-1.84.35-2.46.8-.79 1.84-1.54 2.09-1.67-.8-2.47-.8-1.75 1-2.47.8-.92-1.64-1.54-2.09-2-.18-2.46-.8.23-1.84 0-2.59-1.54-1.3-1.54-2.09 1.28-1.38 1.52-2.09-.44-2 0-2.59 1.85-.35 2.48-.8.78-1.84 1.53-2.12 1.67.83 2.47.83 1.75-1 2.47-.8.91 1.64 1.53 2.09 2 .18 2.46.8-.23 1.84 0 2.59 1.54 1.3 1.54 2.09z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M7.4,11.17,4,8.62,5,7.26l2,1.53L10.64,4l1.36,1Z"
                          ></path>
                        </svg>
                      )}
                      {server.features.includes("PARTNERED") && (
                        <svg
                          aria-hidden="false"
                          width="20"
                          height="20"
                          viewBox="0 0 16 15.2"
                        >
                          <path
                            fill="#5965f3"
                            className="p-1"
                            fill-rule="evenodd"
                            d="m16 7.6c0 .79-1.28 1.38-1.52 2.09s.44 2 0 2.59-1.84.35-2.46.8-.79 1.84-1.54 2.09-1.67-.8-2.47-.8-1.75 1-2.47.8-.92-1.64-1.54-2.09-2-.18-2.46-.8.23-1.84 0-2.59-1.54-1.3-1.54-2.09 1.28-1.38 1.52-2.09-.44-2 0-2.59 1.85-.35 2.48-.8.78-1.84 1.53-2.12 1.67.83 2.47.83 1.75-1 2.47-.8.91 1.64 1.53 2.09 2 .18 2.46.8-.23 1.84 0 2.59 1.54 1.3 1.54 2.09z"
                          ></path>
                          <path
                            d="M10.5906 6.39993L9.19223 7.29993C8.99246 7.39993 8.89258 7.39993 8.69281 7.29993C8.59293 7.19993 8.39317 7.09993 8.29328 6.99993C7.89375 6.89993 7.5941 6.99993 7.29445 7.19993L6.79504 7.49993L4.29797 9.19993C3.69867 9.49993 2.99949 9.39993 2.69984 8.79993C2.30031 8.29993 2.50008 7.59993 2.99949 7.19993L5.99598 5.19993C6.79504 4.69993 7.79387 4.49993 8.69281 4.69993C9.49188 4.89993 10.0912 5.29993 10.5906 5.89993C10.7904 6.09993 10.6905 6.29993 10.5906 6.39993Z"
                            fill="#fff"
                          ></path>
                          <path
                            d="M13.4871 7.79985C13.4871 8.19985 13.2874 8.59985 12.9877 8.79985L9.89135 10.7999C9.29206 11.1999 8.69276 11.3999 7.99358 11.3999C7.69393 11.3999 7.49417 11.3999 7.19452 11.2999C6.39545 11.0999 5.79616 10.6999 5.29674 10.0999C5.19686 9.89985 5.29674 9.69985 5.39663 9.59985L6.79499 8.69985C6.89487 8.59985 7.09463 8.59985 7.19452 8.69985C7.39428 8.79985 7.59405 8.89985 7.69393 8.99985C8.09346 8.99985 8.39311 8.99985 8.69276 8.79985L9.39194 8.39985L11.3896 6.99985L11.6892 6.79985C12.1887 6.49985 12.9877 6.59985 13.2874 7.09985C13.4871 7.39985 13.4871 7.59985 13.4871 7.79985Z"
                            fill="#fff"
                          ></path>{" "}
                        </svg>
                      )}
                      {server.name}
                    </p>
                    <p className="text-gray-400 text-left text-sm">
                      <span className="font-number">
                        {server.memberCount.toLocaleString()}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </Marquee>
          </div>
        </>
      </>
    </div>
  );
}

export default Hero;
