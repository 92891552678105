import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <div className="min-h-[60vh] bg-image text-right py-28 pb-32 px-8">
        <h1 className="text-3xl text-primary py-2">سياسة الخصوصية لبوت فإذكروني</h1>
        <p className="text-gray-400 py-1">
          نحن في بوت فإذكروني ملتزمون بحماية خصوصية مستخدمينا. يرجى قراءة سياسة الخصوصية التالية لفهم كيفية جمع واستخدام وحماية المعلومات التي تقدمها عند استخدام البوت:
        </p>
        <h2 className="text-white text-lg">
          <span className="font-number">1.</span> المعلومات التي نجمعها:
        </h2>
        <p className="text-gray-400">
          عند استخدام البوت، قد نقوم بجمع بعض المعلومات الشخصية التي تقدمها بشكل طوعي، مثل اسم المستخدم وطريقة تفكيره (إذا كان ذلك ضروريًا لتقديم الخدمة).
        </p>
        <h2 className="text-white text-lg">
          <span className="font-number">2.</span> كيفية استخدام المعلومات:
        </h2>
        <p className="text-gray-400">
          نستخدم المعلومات التي نجمعها لتقديم الخدمة وتحسين تجربة المستخدم، ولضمان سلامة وأمان البوت ومستخدميه.
        </p>
        <h2 className="text-white text-lg">
          <span className="font-number">3.</span> مشاركة المعلومات:
        </h2>
        <p className="text-gray-400">
          نحن لا نبيع أو نتاجر بالمعلومات الشخصية للمستخدمين. قد نقوم بمشاركة المعلومات مع الجهات الرسمية إذا كان ذلك مطلوبًا بموجب القانون أو لحماية حقوقنا أو سلامة المستخدمين.
        </p>
        <h2 className="text-white text-lg">
          <span className="font-number">4.</span> الأمان:
        </h2>
        <p className="text-gray-400">
          نحن نتخذ إجراءات أمان مناسبة لحماية المعلومات الشخصية من الوصول غير المصرح به أو الاستخدام أو التعديل أو الإفشاء.
        </p>
        <h2 className="text-white text-lg">
          <span className="font-number">5.</span> الاتصال بنا:
        </h2>
        <p className="text-gray-400">
          إذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصية، يرجى الاتصال بنا عبر سيرفر الدعم الفني المقدم في معلومات الاتصال.
        </p>
        <p className="text-gray-400">
          باستخدامك لهذا البوت، فإنك توافق على جمع واستخدام معلوماتك وفقًا لسياسة الخصوصية المذكورة أعلاه. في حالة عدم الموافقة عليها، يرجى عدم استخدام البوت.
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;