import React, { useEffect, useState } from "react";

import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Commands() {
  const [tab, setTab] = useState("الكل");
  const [commands, setCommands] = useState([]);
  const [arrow, setArrow] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCommands = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/json/commands`);
      const data = await response.json();
      setCommands(data);
      setLoading(false);
    };

    fetchCommands();
  }, []);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollable = document.querySelector("#categories");
      scrollable.scrollLeft = scrollable?.scrollWidth;
    };

    window.addEventListener("load", scrollHandler);
    window.addEventListener("resize", scrollHandler);

    return () => {
      window.removeEventListener("load", scrollHandler);
      window.removeEventListener("resize", scrollHandler);
    };
  }, []);

  const categories = [
    "الكل",
    "أوامر العامة",
    "أوامر المعرفة",
    "أوامر المصحف الشريف",
    "أوامر العاب ومسابقات دينية",
    "أوامر التاريخ ألهجري",
    "اوامر قران كريم",
    "اوامر ميزة صور الآيات",
    "اوامر ميزة الاذاعة مباشر",
    "أوامر ميزة أشعارات الأذان",
    "أوامر ميزة الأدعية",
    "أوامر ميزة الأذكار",
    "أوامر الحج والعمرة",
  ];

  return (
    <div className="min-h-screen">
      <div className="flex flex-col justify-center lg:px-24 max-md:px-12 py-36">
        <h1 className="text-5xl font-medium text-right text-white">
          أوامر فإذكروني
        </h1>
        <p className="text-lg mt-4 text-right text-gray-400">
          .تعرف على الأوامر المتاحة لبوت فإذكروني وعلى طريقة استخدامها
        </p>
        <div className="flex flex-col">
          <p className="text-sm mt-8 mb-2 text-right text-gray-400">
            .اختر النوع المناسب للأوامر
          </p>
          <div className="flex flex-row overflow-x-auto" id="categories">
            {categories.map((type) => (
              <button
                key={type}
                onClick={() => setTab(type)}
                data-value={type}
                className={`text-white text-sm font-medium border-b-2 border-bg-primary p-2 py-1 w-full transition-all ${tab === type
                  ? `border-b-2 border-b-bg-secondary bg-gradient-to-t from-bg-secondary/10 to-transparent`
                  : ``
                  }`}
              >
                {type
                  .replace("أوامر", "")
                  .replace("اوامر", "")
                  .replace("ميزة", "")
                  .replace("ومسابقات", "")
                  .replace("مباشر", "")
                  .replace("أشعارات", "")
                  .replace("كريم", "")
                  .replace("الشريف", "")
                  .trim()}
              </button>
            ))}
          </div>
        </div>
        {loading ? (
          <div className="text-center w-full flex justify-center py-12 relative z-50">
            <div role="status" className="m-auto absolute right-0 left-0">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 animate-spin text-bg-primary fill-bg-secondary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        ) : (
          <>
            <div className="my-4 ml-auto relative">
              <FontAwesomeIcon
                icon={faSearch}
                className="text-white absolute top-1/2 left-2 transform -translate-y-1/2"
              />
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="ابحث عن أمر"
                className="focus:outline-none w-fit p-2 pl-8 rounded-md bg-bg-primary text-white placeholder:text-gray-400 h-10"
              />
            </div>
            <div className="flex flex-col gap-4 w-full">
              {commands
                .filter(
                  (c) =>
                    ((tab == "الكل" && search === "" ? true : tab.includes(c.category) && search === "")) ||
                    (c.command.includes(search) && search !== "")
                )
                .map((command) => (
                  <div key={command.command} className="flex-col gap-0">
                    <div
                      onClick={() =>
                        setArrow({
                          command,
                          bool: arrow.command === command ? !arrow.bool : true,
                        })
                      }
                      className={`flex flex-col text-right gap-2 p-2 rounded-md px-4 bg-dark w-full ml-auto relative cursor-pointer ${arrow.command === command && arrow.bool
                        ? `rounded-b-none`
                        : ``
                        }`}
                    >
                      <button
                        onClick={() =>
                          setArrow({
                            command,
                            bool:
                              arrow.command === command ? !arrow.bool : true,
                          })
                        }
                        className={`text-bg-secondary p-4 rounded-md absolute top-1/2 ${arrow.command === command && arrow.bool
                          ? `rotate-180`
                          : ``
                          } left-2 transform -translate-y-1/2 transition-all`}
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </button>
                      <h3 className="text-lg text-white flex gap-2 items-center">
                        <i
                          className={`bi text-2xl bi-slash-square-fill transition-all ${arrow.command === command && arrow.bool
                            ? `text-bg-secondary`
                            : `text-bg-primary`
                            }`}
                        ></i>
                        {command.command}
                      </h3>
                      <p className="text-gray-400 w-[90%]">
                        {command.description}
                      </p>
                    </div>
                    <div
                      className={`rounded-b-md flex-col gap-2 py-2 h-full px-4 bg-bg-primary/25 ${arrow.command === command && arrow.bool
                        ? "flex h-16"
                        : "hidden h-0"
                        } transition-all`}
                    >
                      <div className="flex w-full justify-between">
                        <span className="text-bg-secondary">الاستخدام</span>
                      </div>
                      <p className="text-white rtl flex gap-1 items-center">
                        /{command.command}{" "}
                        {command.options
                          ?.filter((option) => option.required)
                          .map((option) => (
                            <span
                              key={option.name}
                              className="text-gray-400 rtl bg-bg-primary p-0.5 rounded-md group relative"
                              title={option.description}
                            >
                              {option.name}
                            </span>
                          ))}
                      </p>
                      {command.options?.filter((option) => option.required)
                        .length > 0 && (
                          <>
                            <div className="flex w-full justify-between">
                              <span className="text-bg-secondary">
                                الخيارات المطلوبة
                              </span>
                            </div>
                            {command.options
                              ?.filter((option) => option.required)
                              .map((option) => (
                                <div
                                  key={option.name}
                                  className="flex max-md:flex-col text-right w-full justify-between"
                                >
                                  <span className="text-white">
                                    {option.name}
                                  </span>
                                  <span className="text-gray-400">
                                    {option.description}
                                  </span>
                                </div>
                              ))}
                          </>
                        )}
                      {command.options?.filter((option) => !option.required)
                        .length > 0 && (
                          <>
                            <div className="flex w-full justify-between">
                              <span className="text-bg-secondary">
                                الخيارات الأخرى
                              </span>
                            </div>
                            {command.options
                              ?.filter((option) => !option.required)
                              .map((option) => (
                                <div
                                  key={option.name}
                                  className="flex max-md:flex-col text-right w-full justify-between"
                                >
                                  <span className="text-white">
                                    {option.name}
                                  </span>
                                  <span className="text-gray-400">
                                    {option.description}
                                  </span>
                                </div>
                              ))}
                          </>
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Commands;
