import React from "react";

function FeatureCard({ title, description, icon }) {
  return (
<div className="flex relative group w-[48rem] lg:w-[32rem] max-md:w-[30rem] max-sm:w-full mt-4 z-10 flex-row-reverse gap-4 p-2 rounded-md">
  <span className="absolute top-0 right- opacity-25 w-6 h-6 bg-bg-primary rounded-md flex items-center justify-center">
  </span>
  {icon}
      <div className="flex flex-col z-10">
        <h3 className="text-lg text-right">{title}</h3>
        <p className="text-right text-sm mt-2 text-gray-300">{description}</p>
      </div>
    </div>
  );
}

export default FeatureCard;