import {
    faCalendar,
    faCheck,
    faCopy,
    faLocationDot,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useEffect, useState } from "react";
  import { useParams } from "react-router-dom";
  import { Helmet } from "react-helmet";
  
  function AdhanShare() {
    const [check, setCheck] = useState(null);
    const [copied, setCopied] = useState(false);
    const [data, setData] = useState({});
  
    let { code } = useParams();
  
    const checkImage = async (code) => {
      const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adhan_check/${code}`);
      const response = await data.json();
      setCheck(response.check);
      setData(response.check ? response.data : {});
    };
  
    useEffect(() => {
      checkImage(code);
    }, [code]);
  
    useEffect(() => {
      if (check === false) {
        return`h`;
      }
    }, [check]);
  



    const shareIcons = [
      {
        icon: <i className="bi bi-whatsapp absolute top-[20%] left-[25%]"></i>,
        href: `https://api.whatsapp.com/send?text=مواقيت%20الصلاة:%20${process.env.REACT_APP_API_BASE_URL}/adhan/${code}`,
      },
      {
        icon: <i className="bi bi-facebook absolute top-[20%] left-[25%]"></i>,
        href: `https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_API_BASE_URL}/adhan/${code}`,
      },
      {
        icon: <i className="bi bi-twitter-x absolute top-[20%] left-[25%]"></i>,
        href: `https://twitter.com/intent/tweet?text=مواقيت%20الصلاة:%20${process.env.REACT_APP_API_BASE_URL}/adhan/${code}`,
      },
    ];
  
  return (
    <>
          <Helmet>
        <meta property="og:image" content={data.image_link} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:title" content="مواقيت الصلاة" />
        <meta name="twitter:description" content="شارك مواقيت الصلاة على مواقع التواصل الاجتماعي" />
        <meta name="twitter:image:src" content={data.image_link} />
        <meta name="twitter:card" content="summary_large_image" />
        <title>مواقيت الصلاة</title>
      </Helmet>
    <div className="min-h-screen bg-image">
        {check === null && (
                      <div className="text-center h-screen m-auto flex relative z-50">
                      <div role="status" className="m-auto">
                        <svg
                          aria-hidden="true"
                          className="inline w-10 h-10 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                    </div>          
        )} {check === true && (
      <div className="flex flex-col justify-center relative z-10 gap-4 lg:px-24 max-md:px-12 py-28">
        <h1 className="text-5xl max-md:text-3xl font-medium text-center text-bg-secondary flex flex-row-reverse items-center gap-2 m-auto">
          مواقيت الصلاة
          <img
            src={data.countryFlag}
            alt="flag"
            className="w-12 h-12 rounded-full object-cover inline-block border-4 border-bg-secondary"
          />
        </h1>
        <div className="text-xl mt-4 text-center text-gray-300 flex gap-2 m-auto">
          <p>
            <FontAwesomeIcon
              icon={faLocationDot}
              className="text-bg-secondary"
            />{" "}
            {data.location}
          </p>
          <p>
            <FontAwesomeIcon icon={faCalendar} className="text-bg-secondary" />{" "}
            <span className="font-number">{data.date}</span> {data.day}
          </p>
        </div>
        {check === true ? (
          <img
            src={data.image_link}
            alt="adhan"
            className="w-[32rem] m-auto shadow-md rounded-lg border-[6px] border-bg-secondary hover:border-primary hover:scale-105 transition-all"
          />
        ) : null}
        <div className="flex flex-row-reverse gap-2 justify-center mt-4 relative">
          {shareIcons.map((icon, index) => (
            <>
              <a
                key={index}
                href={icon.href}
                target="_blank"
                rel="noreferrer"
                className="p-6 relative text-center text-white z-10 w-12 bg-bg-primary items-center hover:scale-110 hover:bg-bg-secondary transition-all rounded-md text-2xl"
              >
                {icon.icon}
              </a>
            </>
          ))}
                        <button
                onClick={() => {
                  navigator.clipboard.writeText(`${process.env.REACT_APP_API_BASE_URL}/adhan/${code}`);
                  setCopied(true);
                    setTimeout(() => {
                        setCopied(false);
                    }, 1500);
                }
                }
                className="p-2 relative text-center text-white z-10 items-center hover:text-bg-secondary transition-all rounded-md text-2xl"
              >
                <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
              </button>
        </div>
        <p className="text-lg text-center text-white p-2">
          يمكنك استخدام أمر{" "}
          <span className="bg-bg-primary rounded-md p-1">/مشاركة الأذان</span>{" "}
          من بوت فاذكروني لمشاركة صورة مثل هذه.
        </p>
      </div>
        )}

        {check === false && (
            <div className="text-center h-screen m-auto items-center flex flex-col gap-4 justify-center relative z-50">
                <p className="text-7xl font-number text-bg-secondary">
                    404
                </p>
                <h1 className="text-5xl font-medium text-white">
                لم يتم العثور على الصورة
                </h1>
            </div>
            )}
    </div>
    </>
  );
}

export default AdhanShare;
