import React, { useEffect, useState } from "react";
import { useNavigate, useParams, } from "react-router";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCheck,
  faChevronRight,
  faCopy,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

function Article() {
  const [check, setCheck] = useState(null);
  const [data, setData] = useState({});
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const checkArticle = async (code) => {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/article/${code}`
    );
    var md;
    const response = await data.json();
    if (!response.error) {
      const mddata = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/article/md/${code}`
      );
      md = await mddata.text();
      response.body = md;
    }
    setCheck(response.error ? false : true);
    setData(response.error ? {} : response);
  };

  const handleCopyUrl = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    });
  };

  useEffect(() => {
    checkArticle(id);
  }, [id]);

  const removeLeadingSpaces = (source) => {
    return source
      .split("\n")
      .map((line) => {
        return line.trim();
      })
      .join("\n");
  };

  const shareIcons = [
    {
      icon: (
        <i className="bi bi-whatsapp text-bg-secondary transition-all hover:text-primary"></i>
      ),
      href: `https://api.whatsapp.com/send?text=مواقيت%20الصلاة:%${encodeURIComponent(
        data.title + `\n`
      )}${window.location.href}`,
    },
    {
      icon: (
        <i className="bi bi-facebook text-bg-secondary transition-all hover:text-primary"></i>
      ),
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        data.title + `\n`
      )}${window.location.href}`,
    },
    {
      icon: (
        <i className="bi bi-twitter-x text-bg-secondary transition-all hover:text-primary"></i>
      ),
      href: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        data.title + `\n`
      )}${window.location.href}`,
    },
  ];

  return (
    <div className="min-h-screen bg-image text-right py-28 pb-32 px-24 max-md:px-4 relative z-50">
        <button className="absolute top-24 right-8 transition-all hover:scale-105 hover:text-primary text-bg-secondary flex gap-2 items-center text-xl ml-auto pl-8" onClick={() => navigate('/articles')}>
        <FontAwesomeIcon icon={faChevronRight} className="transition-all" />
            العودة الى المقالات
        </button>
      {check !== null && check === true && (
        <>
          <div className="h-fit w-fit m-auto my-8 p-4 bg-bg-primary bg-opacity-25 backdrop-blur-sm rounded-md">
            {data.image && (
              <>
                <img
                  src={data.image}
                  alt={data.title}
                  className="w-full my-4 m-auto rounded-lg image"
                />
                <div className="h-[1px] bg-bg-primary w-full my-4"></div>
              </>
            )}
            <div className="flex flex-col">
              <div className="flex justify-between gap-2 p-2 max-md:flex-col">
                <h1 className="text-3xl text-primary">{data.title}</h1>
                <div className="flex gap-2 items-center justify-center">
                  {shareIcons.map((icon, index) => {
                    return (
                      <a
                        key={index}
                        href={icon.href}
                        target="_blank"
                        className="hover:scale-110 transition-all"
                        rel="noreferrer"
                      >
                        {icon.icon}
                      </a>
                    );
                  })}
                  <button
                    className="flex items-center text-center justify-center"
                    onClick={handleCopyUrl}
                  >
                    <FontAwesomeIcon
                      icon={copied ? faCheck : faCopy}
                      className="text-bg-secondary"
                    />
                  </button>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between gap-2 p-2">
                <div className="flex flex-row-reverse gap-2 items-center justify-center">
                <p className="text-white text-xl font-arabic font-normal">
                  {data.author.name}
                </p>
                <img
                  src={data.author.icon_url}
                  alt={data.author.name}
                  className="w-9 h-9 rounded-full"
                />
                </div>
                                <div className="flex gap-4 items-center justify-center">
                  <span className="text-white flex gap-2 items-center justify-center font-number">
                    <FontAwesomeIcon
                      icon={faEye}
                      className="text-bg-secondary"
                    />{" "}
                    {data.views}
                  </span>
                  <span className="text-white flex gap-2 items-center justify-center font-number">
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-bg-secondary"
                    />
                    {new Date(data.at).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <MarkdownPreview
            className="text-white rtl font-arabic font-normal pt-8"
            source={removeLeadingSpaces(data.body)}
            disableCopy={true}
            style={{ backgroundColor: "transparent" }}
            wrapperElement={{
              "data-color-mode": "light",
            }}
          />
        </>
      )}
      {check == null && (
        <div className="text-center h-screen m-auto flex relative z-50">
          <div role="status" className="m-auto">
            <svg
              aria-hidden="true"
              className="inline w-10 h-10 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      )}

      {check === false && (
        <div className="text-center h-screen m-auto items-center flex flex-col gap-4 justify-center relative z-50">
          <p className="text-3xl text-primary">لم يتم العثور على المقالة</p>
          <p className="text-gray-400">
            المقالة التي تبحث عنها غير موجودة. يرجى التأكد من الرابط والمحاولة
            مرة أخرى.
          </p>
        </div>
      )}
    </div>
  );
}

export default Article;
