import { useEffect, useState } from "react";

import {
  faPlay,
  faPause,
  faCopy,
  faCheck,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ControlBar({
  currentStation,
  setVolume,
  volume,
  isPlaying,
  handlePlayPause,
  isLoading,
  playingAudio,
}) {
  const [copied, setCopied] = useState(false);
  const [volumeTooltip, setVolumeTooltip] = useState(false);
  const [showControlBar, setShowControlBar] = useState(true);

  const handleVolumeTooltip = () => {
    setVolumeTooltip(!volumeTooltip);
  };

  const handleCopyUrl = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    });
  };
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio >= 0.2) {
            setShowControlBar(false);
          } else {
            setShowControlBar(true);
          }
        });
      },
      { threshold: 0.2 }
    );

    const footer = document.querySelector("#invite");
    if (footer) {
      observer.observe(footer);
    }

    return () => {
      if (footer) {
        observer.unobserve(footer);
      }
    };
  }, []);

  return (
      <div
        className={`fixed bottom-0 left-0 flex right-0 z-50 bg-dark/75 backdrop-blur-md shadow-2xl shadow-black p-4 px-12 w-full justify-between items-center transition-all ${
          currentStation && showControlBar
            ? `opacity-100`
            : `opacity-0`
        }`}
        style={{ border: "none", height: "auto" }}
      >
        <div className="flex gap-4 items-center min-w-[25%]">
          <button
            className="rounded-full p-4 w-12 h-12 bg-dark flex items-center text-center justify-center"
            onClick={() => handlePlayPause(currentStation)}
            disabled={playingAudio === currentStation.value && isLoading}
          >
            {isLoading ? (
              <div className="text-center m-0 flex relative z-50">
                <div role="status" className="m-auto">
                  <svg
                    aria-hidden="true"
                    className="inline w-10 h-10 p-3 text-gray-200 animate-spin dark:text-bg-primary fill-bg-secondary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <>
                {isPlaying ? (
                  <FontAwesomeIcon
                    icon={faPause}
                    className="text-white text-xl"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlay}
                    className="text-white text-xl"
                  />
                )}
              </>
            )}
          </button>
        </div>
        <div className="flex items-center min-w-[50%]">
          <h1 className="text-white m-auto">{currentStation?.name}</h1>
        </div>
        <div className="flex gap-4 items-center min-w-[25%] justify-center">
          <span className="text-white font-number">{volume}%</span>
          <button
            className="items-center text-center justify-center hidden max-md:flex"
            onClick={handleVolumeTooltip}
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`text-bg-secondary text-xl transition-all ${
                volumeTooltip ? `rotate-180` : ``
              }`}
            />
          </button>
          <div
            id="volume-tooltip"
            className={`absolute bottom-16 left-[${
              isIOS ? `40%` : `25%`
            }] transform -translate-x-1/2 bg-dark p-4 flex flex-row  items-center gap-2 rounded-lg shadow-2xl ${
              volumeTooltip ? `max-md:flex` : `hidden`
            }`}
          >
            {isIOS ? (
              <>
                <span className="text-white text-center text-sm">
                  لا يمكن التحكم في مستوى الصوت على أجهزة iOS
                </span>
              </>
            ) : (
              <input
                id="steps-range"
                type="range"
                min="0"
                max="100"
                className="w-full h-2 bg-bg-primary rounded-lg appearance-none cursor-pointer"
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
              />
            )}
            <button
              className="flex items-center text-center justify-center"
              onClick={handleCopyUrl}
            >
              <FontAwesomeIcon
                icon={copied ? faCheck : faCopy}
                className="text-bg-secondary text-xl"
              />
            </button>
          </div>
          <input
            id="steps-range"
            type="range"
            min="0"
            max="100"
            className="w-full h-2 bg-bg-primary rounded-lg appearance-none cursor-pointer max-md:hidden"
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
          />
          <button
            className="flex items-center text-center justify-center max-md:hidden"
            onClick={handleCopyUrl}
          >
            <FontAwesomeIcon
              icon={copied ? faCheck : faCopy}
              className="text-bg-secondary text-3xl"
            />
          </button>
        </div>
      </div>
  );
}

export default ControlBar;
