import { faBookQuran, faCloudMoon, faFireFlameCurved, faMosque, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeatureCard from "./FeatureCard";
import React from "react";

function Features() {


    const features = [
        {
          icon: <FontAwesomeIcon icon={faCloudMoon} size="2xl" className="z-10 text-bg-secondary" />,
          title: "أذكار وأدعية",
          description: "تصفح البوت لأذكار وأدعية تلهمك وتجعل يومك أكثر إشراقًا! فقط حدد الوقت والمكان، واترك البوت يفعل الباقي بإرسالها في الروم الخاص بك بأسلوب مميز وصورة تليق بسيرفرك.",
    },
        {
          icon: <FontAwesomeIcon icon={faMosque} size="2xl" className="z-10 text-bg-secondary" />,
          title: "اشعارات بمواقيت الصلاة",
          description: "لن تفوتك صلواتك بعد الآن! اختر المدينة واستمتع بتلقي اشعارات مواقيت الصلاة مباشرة في الروم الخاص بك. وأضف إلى ذلك، يمكنك ضبط مواقيت لأكثر من خمسة أماكن في سيرفر واحد!"
        },
        {
          icon: <FontAwesomeIcon icon={faBookQuran} size="2xl" className="z-10 text-bg-secondary" />,
          title: "مقاطع آيات قصيرة",
          description: "هل تبحث عن لحظة سكينة وتأمل؟ اطلب من البوت إرسال مقاطع قرآنية قصيرة كل مدة معينة في الروم الخاص بك، لتملأ روحك بالهدوء والتأمل."
        },
        {
          icon: <FontAwesomeIcon icon={faTrophy} size="2xl" className="z-10 text-bg-secondary" />,
          title: "العاب دينية تنافسية",
          description: "استعد لتحدي العقول والقلوب مع فعاليات الألعاب الدينية المثيرة! اختبر معلوماتك بالأسئلة الدينية العامة وآيات من القرآن الكريم، وانطلق في مسار الفائزين بأسلوب ممتع ومفيد."
        },
      ];      
  return (
    <>
      <div id='features' className="flex flex-col scroll-m-24 items-center bg-gradient-to-b p-12 from-dark to-transparent min-h-screen text-text-primary w-full m-auto">
        <h1 className="text-5xl font-medium">المميزات</h1>
        <div className="relative">
        <div className="absolute bottom-2 right-0 w-24 h-4 bg-bg-primary/25">
            </div>
            <p className="text-lg mt-4 text-center z-50 relative text-bg-secondary">
            ابرز مميزات بوت فإذكروني
            </p>
        
</div>
        <div className="grid grid-cols-1 lg:grid-cols-2 place-items-center w-full gap-4 mt-24">
            {features.map((feature, index) => (
                <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
                />
            ))}
        </div>
        <h1 className="text-4xl mt-16 lg:text-4xl font-medium flex flex-row-reverse gap-2"><FontAwesomeIcon icon={faFireFlameCurved} className="text-bg-secondary" /> ...والمزيد</h1>
        <p className="text-lg mt-4 text-center lg:w-1/2 mb-8 md:mb-14 max-md:mb-24">
        لا تنتهي مفاجآت البوت هنا! استمتع بمسبحة إلكترونية وحساب الزكاة بمختلف أنواعه، واستفد من الفتاوى والأحاديث وتصفح المصحف واكتشف الأحداث التاريخية الإسلامية والمزيد الكثير
        </p>
      </div>
    </>
  );
}

export default Features;
